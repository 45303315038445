<template>
  <div class="no-coupon" data-testid="no-coupon-container">
    <div class="no-coupon__icon-container" data-testid="no-coupon-icon-container">
      <Icon name="ticket" prefix="fal" :classes="['no-coupon__icon']" size="md" data-testid="no-coupon-icon" />
    </div>

    <div class="no-coupon__content" data-testid="no-coupon-content">
      <h2 class="no-coupon__title" data-testid="no-coupon-title">
        {{ $t('global-coupon.no-coupon.title') }}
      </h2>

      <p class="no-coupon__description" data-testid="no-coupon-description">
        {{ $t('global-coupon.no-coupon.description') }}
      </p>
    </div>

    <MXButton data-testid="register-coupon-button" @click="$emit('register-coupon')">
      {{ $t('global-coupon.coupons-list.register-button') }}
    </MXButton>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
import MXButton from '@/shared/components/MXButton.vue';

export default {
  name: 'NoCoupon',
  components: { Icon, MXButton },
};
</script>

<style lang="scss">
.no-coupon {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  margin: 0 auto;
  max-width: 338px;
}

.no-coupon__icon-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f3ebfa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-coupon__icon {
  color: $purple-dark;
}

.no-coupon__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

.no-coupon__title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: $grey;
}

.no-coupon__description {
  font-size: 0.877rem;
  color: $grey;
  text-align: center;
  line-height: 1.313rem;
  margin-bottom: 0;
}
</style>
