<template>
  <div class="coupons-cards" data-testid="coupons-cards">
    <div v-for="coupon in coupons" :key="coupon.id" class="coupons-card" :data-testid="`coupon-card-${coupon.id}`">
      <div class="coupons-card__header" data-testid="coupon-card-header">
        <span class="coupons-card__code" data-testid="coupon-code">
          {{ coupon.code }}
        </span>
        <button class="delete-button" data-testid="delete-coupon-button" @click="$emit('delete', coupon.id)">
          <Icon name="trash-alt" prefix="fal" data-testid="delete-icon" />
        </button>
      </div>
      <div class="coupons-card__info" data-testid="coupon-info">
        <div class="coupons-card__info-row">
          <span class="coupons-card__label" data-testid="discount-label">
            DESCONTO
          </span>
          <span class="coupons-card__value" data-testid="discount-value">
            {{ formatDiscount(coupon) }}
          </span>
        </div>
        <div class="coupons-card__info-row coupons-card__info-row--right">
          <span class="coupons-card__label" data-testid="quantity-label">
            QUANTIDADE
          </span>
          <span class="coupons-card__value" data-testid="quantity-value">
            {{ couponQuantityText(coupon.quantity) }}
          </span>
        </div>
        <div class="coupons-card__info-row">
          <span class="coupons-card__label" data-testid="validity-label">
            VALIDADE
          </span>
          <span class="coupons-card__value" data-testid="validity-value">
            {{ formatDate(coupon.available_until) }}
          </span>
        </div>
        <div class="coupons-card__info-row coupons-card__info-row--right">
          <span class="coupons-card__label" data-testid="used-label">
            USADOS
          </span>
          <span class="coupons-card__value" data-testid="used-value">
            {{ coupon.consumed }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
import { formatDate, formatDiscount } from '../utils';

export default {
  name: 'GlobalCouponsCards',
  components: {
    Icon,
  },
  props: {
    coupons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDiscount,
    formatDate,
    couponQuantityText(quantity) {
      if (quantity >= 999999) return 'Ilimitado';

      return quantity;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupons-cards {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.coupons-card {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 16px;
}

.coupons-card__info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 18px;
}

.coupons-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.coupons-card__code {
  font-weight: 600;
}

.coupons-card__info-row {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.coupons-card__info-row--right {
  text-align: right;
}

.coupons-card__label {
  color: $grey;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.coupons-card__value {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.delete-button {
  background: none;
  border: none;
  color: #db3939;
  font-size: 0.75rem;
  cursor: pointer;
  padding: 0 8px;
}

@media (min-width: $screen-sm) {
  .coupons-cards {
    display: none;
  }
}
</style>
