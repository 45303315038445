<template>
  <div class="coupons-table" data-testid="coupons-table">
    <div class="coupons-table__header" data-testid="coupons-table-header">
      <div class="coupons-table__cell" data-testid="header-name">
        {{ $t('global-coupon.table.headers.name') }}
      </div>
      <div class="coupons-table__cell" data-testid="header-discount">
        {{ $t('global-coupon.table.headers.discount') }}
      </div>
      <div class="coupons-table__cell" data-testid="header-quantity">
        {{ $t('global-coupon.table.headers.quantity') }}
      </div>
      <div class="coupons-table__cell" data-testid="header-used">
        {{ $t('global-coupon.table.headers.used') }}
      </div>
      <div class="coupons-table__cell" data-testid="header-validity">
        {{ $t('global-coupon.table.headers.validity') }}
      </div>
      <div class="coupons-table__cell"></div>
    </div>

    <div v-for="coupon in coupons" :key="coupon.id" class="coupons-table__row" :data-testid="`coupon-row-${coupon.id}`">
      <div class="coupons-table__cell" data-testid="coupon-code">
        {{ coupon.code }}
      </div>
      <div class="coupons-table__cell" data-testid="coupon-discount">
        {{ formatDiscount(coupon) }}
      </div>
      <div class="coupons-table__cell" data-testid="coupon-quantity">
        {{ couponQuantityText(coupon.quantity) }}
      </div>
      <div class="coupons-table__cell" data-testid="coupon-consumed">
        {{ coupon.consumed }}
      </div>
      <div class="coupons-table__cell" data-testid="coupon-validity">
        {{ formatDate(coupon.available_until) }}
      </div>
      <div class="coupons-table__cell">
        <button class="delete-button" data-testid="delete-coupon-button" @click="$emit('delete', coupon.id)">
          <Icon name="trash-alt" prefix="fal" data-testid="delete-icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components-new/Icon.vue';
import { formatDate, formatDiscount } from '../utils';

export default {
  name: 'GlobalCouponsTable',
  components: {
    Icon,
  },
  props: {
    coupons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDiscount,
    formatDate,
    couponQuantityText(quantity) {
      if (quantity >= 999999) return 'Ilimitado';

      return quantity;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupons-table {
  width: 100%;
  display: none;
}

.coupons-table__header {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 60px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
  color: #6f6f6f;
  padding-left: 20px;
  padding-right: 20px;
}

.coupons-table__row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 60px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
  color: $grey;
  padding: 12px 20px;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-top: 20px;
}

.coupons-table__cell {
  display: flex;
  align-items: center;
}

.delete-button {
  background: none;
  border: none;
  color: #db3939;
  font-size: 0.75rem;
  cursor: pointer;
  padding: 0 8px;
}

@media (min-width: $screen-sm) {
  .coupons-table {
    display: block;
  }
}
</style>
