<template>
  <div class="global-coupons-list-wrapper" data-testid="global-coupons-list">
    <div class="global-coupons-list">
      <div class="coupons-header" data-testid="coupons-header">
        <h2 class="coupons-header__title" data-testid="coupons-header-title">
          {{ $t('global-coupon.coupons-list.title') }}
        </h2>
        <p class="coupons-header__description" data-testid="coupons-header-description">
          {{ $t('global-coupon.coupons-list.description') }}
        </p>
        <MXButton class="coupons-header__button" data-testid="register-coupon-button" @click="$emit('register-coupon')">
          {{ $t('global-coupon.coupons-list.register-button') }}
        </MXButton>
      </div>

      <GlobalCouponsTable :coupons="coupons" data-testid="coupons-table" @delete="deleteCoupon" />
    </div>

    <GlobalCouponsCards :coupons="coupons" data-testid="coupons-cards" @delete="deleteCoupon" />
  </div>
</template>

<script>
import MXButton from '@/shared/components/MXButton.vue';
import GlobalCouponsTable from './GlobalCouponsTable.vue';
import GlobalCouponsCards from './GlobalCouponsCards.vue';

export default {
  name: 'GlobalCouponsList',
  components: {
    MXButton,
    GlobalCouponsTable,
    GlobalCouponsCards,
  },
  props: {
    coupons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    deleteCoupon(id) {
      this.$emit('delete-coupon', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.global-coupons-list-wrapper {
  width: 100%;
}

.global-coupons-list {
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
}

.coupons-header {
  margin-bottom: 30px;
}

.coupons-header__button {
  width: 100%;

  @media (min-width: $screen-sm) {
    width: auto;
  }
}

.coupons-header__title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 16px;
  color: $grey;
}

.coupons-header__description {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-bottom: 16px;
}
</style>
