<template>
  <div data-testid="global-coupon-page">
    <PageHeader
      data-testid="global-coupon-header"
      :title="$t('global-coupon.header.title')"
      :sub-title="$t('global-coupon.header.subtitle')"
      :back-text="$t('global-coupon.header.back-text')"
      :back-only-mobile="false"
      back-route="MyAccountDashboard"
    />
    <section class="global-coupon">
      <GlobalCouponsList
        v-if="hasCoupons"
        :coupons="coupons"
        data-testid="global-coupons-list"
        @register-coupon="openCouponCreationDrawer"
        @delete-coupon="openDeleteCouponModal"
      />
      <NoCoupon v-else data-testid="no-coupon" @register-coupon="openCouponCreationDrawer" />

      <div
        class="global-coupon-info"
        :class="{ 'global-coupon-info--short': !hasCoupons }"
        data-testid="global-coupon-info"
      >
        <Icon name="info-circle" prefix="fal" />
        <p class="global-coupon-info__text">
          {{ $t('global-coupon.info.subscription-text') }}
        </p>
      </div>
    </section>

    <Drawer
      v-if="isCouponCreationDrawerOpen"
      :title="$t('global-coupon.creation-drawer.title')"
      data-testid="coupon-creation-drawer"
      @close="closeCouponCreationDrawer"
    >
      <CouponCreationForm data-testid="coupon-creation-form" @form-updated="setFormData" />
      <template #footer>
        <div class="global-coupon__buttons-wrapper">
          <MXButton variant="tertiary" data-testid="cancel-button" @click="closeCouponCreationDrawer">
            {{ $t('global-coupon.creation-drawer.buttons.cancel') }}
          </MXButton>
          <MXButton :disabled="!formIsValid" data-testid="save-button" @click="createGlobalCoupon">
            {{ $t('global-coupon.creation-drawer.buttons.save') }}
          </MXButton>
        </div>
      </template>
    </Drawer>

    <hsConfirmModal
      id="delete-global-coupon-modal"
      data-testid="delete-coupon-modal"
      icon="trash-alt"
      variant="cherry"
      :customTitle="$t('global-coupon.delete-modal.title')"
      :description="$t('global-coupon.delete-modal.description')"
      :ok-title="$t('global-coupon.delete-modal.buttons.delete')"
      :cancel-title="$t('global-coupon.delete-modal.buttons.keep')"
      @hidden="() => (deleteCouponId = null)"
      @ok="deleteCoupon"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/_structures/PageHeader.vue';
import NoCoupon from './components/NoCoupon.vue';
import CouponCreationForm from './components/CouponCreationForm.vue';
import GlobalCouponsList from './components/GlobalCouponsList.vue';
import Drawer from '@/components-new/Drawer.vue';
import MXButton from '@/shared/components/MXButton.vue';
import ToasterHelper from '@/shared/helpers/toast';
import { couponService } from '@/services';
import { hsConfirmModal } from '@/components';
import Icon from '@/components-new/Icon.vue';

export default {
  name: 'GlobalCoupon',
  components: {
    PageHeader,
    NoCoupon,
    Drawer,
    CouponCreationForm,
    MXButton,
    GlobalCouponsList,
    hsConfirmModal,
    Icon,
  },
  data() {
    return {
      isCouponCreationDrawerOpen: false,
      formData: null,
      coupons: [],
      currentPage: 1,
      deleteCouponId: null,
    };
  },
  computed: {
    hasCoupons() {
      return this.coupons.length > 0;
    },
    formIsValid() {
      if (!this.formData) return false;

      const { errors } = this.formData;
      return Object.values(errors).every(error => error.valid && error.touched);
    },
  },
  methods: {
    openCouponCreationDrawer() {
      this.isCouponCreationDrawerOpen = true;
    },
    closeCouponCreationDrawer() {
      this.isCouponCreationDrawerOpen = false;
    },
    openDeleteCouponModal(couponId) {
      this.deleteCouponId = couponId;
      this.$bvModal.show('delete-global-coupon-modal');
    },
    async createGlobalCoupon() {
      try {
        await couponService.createGlobal(this.formData);
        this.closeCouponCreationDrawer();
        this.getGlobalCoupons();
        ToasterHelper.successMessage(this.$t('global-coupon.toasts.create.success'));
      } catch (error) {
        ToasterHelper.dangerMessage(this.$t('global-coupon.toasts.create.error'));
      }
    },
    async deleteCoupon() {
      try {
        await couponService.delete(this.deleteCouponId);
        this.getGlobalCoupons();
        ToasterHelper.successMessage(this.$t('global-coupon.toasts.delete.success'));
      } catch (error) {
        ToasterHelper.dangerMessage(this.$t('global-coupon.toasts.delete.error'));
      } finally {
        this.deleteCouponId = null;
      }
    },
    setFormData(formData) {
      this.formData = formData;
    },
    async getGlobalCoupons() {
      try {
        const response = await couponService.getGlobal([
          { key: 'per_page', value: '100' },
          { key: 'page', value: this.currentPage },
        ]);
        this.coupons = response.coupons;
      } catch (error) {
        ToasterHelper.dangerMessage(this.$t('global-coupon.toasts.list.error'));
      }
    },
  },
  async mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    await this.getGlobalCoupons();
  },
};
</script>

<style lang="scss">
.global-coupon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;

  @media (min-width: 768px) {
    padding: 40px;
  }
}

.global-coupon__buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: $screen-sm) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.global-coupon-info {
  padding: 8px 16px;
  background-color: #efefef;
  border-radius: 4px;
  border: 0.5px solid #cfcfcf;
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-top: 24px;
  width: 100%;

  @media (min-width: $screen-sm) {
    padding: 8px 20px;
    margin-left: 0;
    margin-right: 0;
  }
}

.global-coupon-info--short {
  margin-top: 42px;
  max-width: 660px;
}

.global-coupon-info__text-wrapper {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.global-coupon-info__text {
  color: $grey;
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin: 0;
}
</style>
