<template>
  <div class="coupon-form" data-testid="coupon-form">
    <div class="coupon-form__field" data-testid="code-field">
      <MInput
        id="code"
        :label="$t('global-coupon.creation-drawer.code.label')"
        v-model="form.code"
        type="text"
        :placeholder="$t('global-coupon.creation-drawer.code.placeholder')"
        :has-error="!errors.code.valid && errors.code.touched"
        @input="validateCode"
        @blur="validateCode"
        data-testid="code-input"
      />
      <span class="coupon-form__helper-text" data-testid="code-helper-text">
        {{ $t('global-coupon.creation-drawer.code.helper-text') }}
      </span>
    </div>

    <div class="coupon-form__field" data-testid="quantity-field">
      <div class="coupon-form__quantity-wrapper">
        <MInput
          id="quantity"
          v-model="form.quantity"
          v-mask="'#####'"
          :label="$t('global-coupon.creation-drawer.quantity.label')"
          :max-length="5"
          :disabled="form.unlimited"
          :display-counter="false"
          class="coupon-form__input"
          :has-error="!errors.quantity.valid && errors.quantity.touched"
          @input="validateQuantity"
          @blur="validateQuantity"
          data-testid="quantity-input"
        />

        <MXInputCheckbox
          id="unlimited"
          v-model="form.unlimited"
          :label="$t('global-coupon.creation-drawer.quantity.unlimited-label')"
          class="coupon-form__unlimited-checkbox"
          @input="validateQuantity"
          data-testid="unlimited-checkbox"
        />
      </div>
    </div>

    <div class="coupon-form__field" data-testid="valid-until-field">
      <p class="coupon-form__input-label" data-testid="valid-until-label">
        {{ $t('global-coupon.creation-drawer.valid-until.label') }}
      </p>
      <hs-date
        v-model="form.validUntil"
        :label-no-date-selected="$t('date.format')"
        :min="new Date()"
        value-as-date
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        locale="pt-BR"
        class="coupon-form__valid-until-input"
        data-testid="valid-until-input"
      >
        <template slot="button-content">
          <Icon name="calendar" prefix="fal" data-testid="calendar-icon" />
        </template>
      </hs-date>
    </div>

    <div class="coupon-form__field" data-testid="discount-field">
      <MInput
        id="discount"
        :value="form.discount"
        :max-length="5"
        :display-counter="false"
        :label="$t('global-coupon.creation-drawer.discount.label')"
        icon="percentage"
        icon-variant="light"
        class="coupon-form__discount-input"
        @input="handleDiscountInput"
        @blur="validateDiscount"
        :has-error="!errors.discount.valid && errors.discount.touched"
        data-testid="discount-input"
      />
    </div>
  </div>
</template>

<script>
import MInput from '@/components/MInput.vue';
import MXInputCheckbox from '@/shared/components/MXInputCheckbox.vue';
import Icon from '@/components-new/Icon.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'CouponCreationForm',
  directives: { mask },
  components: {
    MInput,
    MXInputCheckbox,
    Icon,
  },
  data() {
    return {
      form: {
        code: '',
        quantity: 0,
        unlimited: false,
        validUntil: new Date(),
        discount: '0.00',
      },
      errors: {
        code: {
          valid: true,
          touched: false,
        },
        quantity: {
          valid: true,
          touched: false,
        },
        discount: {
          valid: true,
          touched: false,
        },
      },
    };
  },
  methods: {
    validateCode() {
      this.errors.code.valid = this.form.code.trim().length > 0;
      this.errors.code.touched = true;
    },
    validateQuantity() {
      this.errors.quantity.touched = true;

      if (this.form.unlimited) {
        this.errors.quantity.valid = true;
        return;
      }

      this.errors.quantity.valid = Number(this.form.quantity) > 0;
    },
    validateDiscount() {
      this.errors.discount.touched = true;
      this.errors.discount.valid = Number(this.form.discount) > 0;
    },
    handleDiscountInput(value) {
      const numbers = value.replace(/\D/g, '');
      if (!numbers) {
        this.form.discount = '0.00';
        return;
      }

      let newValue = parseFloat(numbers) / 100;

      if (newValue > 90) {
        newValue = 90;
      }

      this.form.discount = newValue.toFixed(2);
      this.validateDiscount();
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('form-updated', {
          ...newVal,
          errors: this.errors,
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-form {
  &__field {
    margin-bottom: 24px;
  }

  &__helper-text {
    display: block;
    font-size: 0.75rem;
    line-height: 1.113rem;
    color: $grey;
    margin-top: 4px;
  }

  &__quantity-wrapper {
    display: grid;
    grid-template-columns: 171px 1fr;
    column-gap: 12px;
    align-items: flex-end;

    @media (max-width: $screen-xs) {
      grid-template-columns: 149px 1fr;
    }
  }

  &__unlimited-checkbox {
    margin-bottom: 0;

    @media (min-width: 372px) {
      margin-bottom: 10px;
    }
  }

  &__valid-until-input {
    width: 171px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 2px 0;
    font-size: 0.875rem;
  }

  &__discount-input {
    width: 100%;

    @media (min-width: $screen-xs) {
      width: 316px;
    }
  }

  &__input-label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}
</style>
