import { render, staticRenderFns } from "./CouponCreationForm.vue?vue&type=template&id=92f4c194&scoped=true&"
import script from "./CouponCreationForm.vue?vue&type=script&lang=js&"
export * from "./CouponCreationForm.vue?vue&type=script&lang=js&"
import style0 from "./CouponCreationForm.vue?vue&type=style&index=0&id=92f4c194&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f4c194",
  null
  
)

export default component.exports